@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x:hidden;
  background: rgba(255, 251, 244, 1) 
}


