/* @keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-slide-left {
  animation: slideLeft 3s linear infinite;
}

.home-hero {
  display: flex;
  overflow: hidden;
  position: relative;
}

/* Add a delay to each item to create a seamless loop */
/* .animate-slide-left {
  animation-delay: calc(var(--animate-order) * 2s);
}  */

:root {
  --animation-speed: 8s;
}

@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * var(--item-count))); }
}

.slider {
    background: linear-gradient(to left, #fcc419, #f59e0b);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    padding: 8PX;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before,
    &::after {
        background-color: linear-gradient(to left, #fcc419, #f59e0b);
        content: "";
        height: 100px;
        position: absolute;
        /* width: 200px; */
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll var(--animation-speed) linear infinite;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(320px * var(--item-count));
    }

    .slide {
      /* width: 200px; */
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 4px;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }
    p{
      font-size: 20px;
    }

}
