
  /* CSS */
.services-check span input[type="checkbox"] {
    -webkit-appearance: none; /* Remove default appearance */
    -moz-appearance: none; /* Remove default appearance */
    appearance: none; /* Remove default appearance */
    width: 25px; /* Increase width */
    height: 25px; /* Increase height */
    border-radius: 50%; /* Make it rounded */
    border: 2px solid #aaa; /* Add border */
    outline: none; /* Remove outline */
    cursor: pointer; /* Change cursor to pointer */
    transition: all 0.3s ease; /* Add transition for smooth effect */
  }
  
  /* Change background color when checked */
  .services-check span input[type="checkbox"]:checked {
    background-color: #F9CD2E
  }
  
  /* Customize the checkmark */
  .services-check span input[type="checkbox"]:checked::before {
    content: "\2713"; /* Unicode checkmark character */
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 25px; /* Center the checkmark vertically */
    font-size: 18px;
    color: white; /* Change color of checkmark */
  }

  .contact-form{
    background-color: #FFF9E0;
  }
  .contact-address{
    background-color:#2D2500;
    height: 50vw;
  }
  .contact-form input{
    background-color: #FFF9E0;
  }

  

  